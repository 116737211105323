export const prepareHeaders = (headers: any, { getState }: any) => {
  const token = getState().auth.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

//   export const bucketBaseUrl = import.meta.env.VITE_BUCKET_BASEURL;

export const baseUrl = "https://backend.bloomkidz.net/";
