import { connect } from "react-redux";
import Routers from ".";
import { getUserInfoData } from "../ReduxToolkit/Slices/Auth/AuthReducer";

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserInfo: () => dispatch(getUserInfoData({})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routers);
