import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    InputGroup,
    InputGroupText,
    Label,
    Row,
  } from "reactstrap";
  import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
  import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
  import DropItem from "../../../Components/Forms/Common/DropItem";
  import { Btn } from "../../../AbstractElements";
  import {
    FaqsTitle,
    FaqsContent,
    LooksGood,
    SubmitForm,
    UserName,
    Zip,
  } from "../../../Utils/Constants";
  import { useEffect, useState } from "react";
  import { Controller, useForm } from "react-hook-form";
  import { FormDataTypes } from "../../../Types/Forms.type";
  import { useNavigate, useParams } from "react-router-dom";
  import DatePicker from "react-datepicker";
  import { connectComponent } from "./connector";
  import Swal from "sweetalert2";
  import { IForm } from "./Helper/types";
  import ReactQuill from "react-quill";
  
  const EditFaqs = (props: any) => {
    const navigate = useNavigate();
    const { id } = useParams();
  
    const initialState = {
      title: "",
      content: "",
    };
  
    const [form, setForm] = useState<IForm>({
      ...initialState,
    });
  
    const [validate, setValidate] = useState(false);
    const {
      register,
      reset,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<IForm>();
  
    const saveDataForm = (name: any, value: any) => {
      setForm({ ...form, [name]: value });
    };

    useEffect(() => {
        const editItem = props.faqs.filter((data: any) => data.id === id);
        if (editItem.length > 0) {
          const formData = {
            title: editItem[0].title,
            content: editItem[0].content,      
          };
          reset(formData); // Reset the form with fetched user data
          setForm(formData);
        }
      }, [id, reset]);
  
    useEffect(() => {
      if (props.success && props.success?.message) {
        Swal.fire({
          title: "Successfully !!!",
          text: props.success.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`${process.env.PUBLIC_URL}/dashboard/help-center/faq`, {
              replace: true,
            });
            // Clear the success message after navigating
            props.setMessage({ success: {}, error: {} });
          }
        });
      }
  
      if (props.error && props.error?.message) {
        Swal.fire({
          title: "Error !!!",
          text: props.error.message,
          icon: "error",
        });
  
        // Clear the error message after displaying it
        props.setMessage({ success: {}, error: {} });
      }
    }, [props.success, props.error, navigate]);
  
  
    const onSubmit = () => {
        setValidate(true);
        props.updateForm({
          id: id,
          body: form,
        });
      };
    return (
      <div className="page-body">
        <Container fluid>
          <Row className="pt-4">
            <Col sm={12}>
              <Card>
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ borderBottom: "5px solid #eee" }}
                >
                  <CommonCardHeader
                    headClass="pb-0 card-no-border"
                    title={`Add FAQ Details`}
                    titleClass="mb-3"
                  />
                  <Button
                    color="dark"
                    className="btn-md m-4 d-flex align-items-center"
                    onClick={() => {
                      navigate(
                        `${process.env.PUBLIC_URL}/dashboard/help-center/faq`
                      );
                    }}
                  >
                    <i
                      className="iconly-Arrow-Left icli"
                      style={{ marginRight: "0.5rem" }}
                    ></i>{" "}
                    Backsssx
                  </Button>
                </div>
  
                <CardBody>
                  <Form
                    className="needs-validation custom-input tooltip-valid validation-forms"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                  >
                    <Row className="g-3">
                      <Col md={12}>
                        <span className="h4">
                          <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                          Create New
                        </span>
                        <Row className="mt-3">
                        <Col md={6} className="position-relative">
                            <Label>
                              {"Title"} <span className="text-danger">*</span>
                            </Label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.title ? "is-invalid" : ""
                              } ${
                                validate && !errors.title ? "is-valid" : ""
                              }`}
                              placeholder="Enter title"
                              {...register("title", {
                                required: "Please enter valid title",
                              })}
                              value={form.title}
                              onChange={(e) =>
                                saveDataForm("title", e.target.value)
                              }
                            />
                            {errors.title && (
                              <FormFeedback tooltip>
                                {errors.title.message}
                              </FormFeedback>
                            )}
                            {validate && !errors.title && (
                              <FormFeedback tooltip valid>
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </Col>
                          <Col md={12} className="mt-3 position-relative">
                            <Label>
                              {`Content`} <span className="text-danger">*</span>
                            </Label>
                            <Controller
                              name="content"
                              control={control}
                              defaultValue=""
                              rules={{ required: "Content is required" }} // Validation for the content field
                              render={({ field }) => {
                                // console.log("field: ", field);
                                return (
                                  <ReactQuill
                                    theme="snow"
                                    value={field.value}
                                    onChange={(value) => {
                                      field.onChange(
                                        value === "<p><br></p>" ? "" : value
                                      );
                                      saveDataForm(
                                        "content",
                                        value === "<p><br></p>" ? "" : value
                                      );
                                    }} // Update form value on change
                                    onBlur={field.onBlur} // Optional: handle onBlur event
                                  />
                                );
                              }}
                            />
                            {errors.content && (
                              <FormFeedback
                                tooltip
                                style={{
                                  display: "block",
                                  top: "-7px",
                                  left: "120px",
                                  right: "unset",
                                }}
                              >
                                {errors.content.message}
                              </FormFeedback>
                            )}
                            {validate && !errors.content && (
                              <FormFeedback
                                tooltip
                                valid
                                style={{ display: "block" }}
                              >
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                        <Btn
                          color="dark"
                          className="mt-3 btn-md"
                          style={{ marginRight: "0.5rem" }}
                        >
                          {`Update & Save`}
                        </Btn>
                        <Btn
                          color="primary"
                          outline={true}
                          type="button"
                          onClick={() => {
                            navigate(
                              `${process.env.PUBLIC_URL}/dashboard/help-center/faq`
                            );
                          }}
                          className="mt-3 btn-md"
                        >
                          {`Cancel`}
                        </Btn>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  export default connectComponent(EditFaqs);
  