import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../AbstractElements";
import { FirstName, LastName, LooksGood } from "../../../Utils/Constants";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { connectComponent } from "./connector";
import { IForm, ValidKeys } from "./Helper/types";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const AddUsers = (props: any) => {
  const navigate = useNavigate();

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    startedAt: new Date().getTime(),
    expiredAt: new Date().setFullYear(new Date().getFullYear() + 1),
    joinDate: new Date(),
    paymentStatus: "PAID",
    nurseryObjArr: [
      {
        name: "",
      },
    ],
    packageId: "",
  };

  const [form, setForm] = useState<IForm>({
    ...initialState,
  });

  const [plansData, setPlansData] = useState([]);

  const [validate, setValidate] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>();

  console.log("errors: ", errors);
  const onSubmit = () => {
    setValidate(true);
    console.log("form: ", form);
    if (form.packageId === "") {
      toast.error("Please provide package");
    } else {
      form.joinDate = new Date(form.joinDate).getTime();
      props.addForm(form);
    }
  };

  const saveDataForm = (name: ValidKeys, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    props.fetchPlans({});
  }, []);

  useEffect(() => {
    const data = props.plans.map((plan: any) => {
      return {
        label: `${plan.title} - £${plan.fee.toFixed(
          0
        )}/${plan.billing.toLowerCase()} (${
          plan.isActive ? "Active" : "Inactive"
        })`,
        value: plan.id,
      };
    });
    setPlansData(data);
  }, [props.plans]);

  const handleAddField = () => {
    setForm({
      ...form,
      nurseryObjArr: [
        ...form.nurseryObjArr,
        {
          name: "",
        },
      ],
    });
  };

  const handleRemoveField = (indexToRemove: number) => {
    if (form.nurseryObjArr.length === 1) {
      return;
    }

    const updatedNurseryNames = form.nurseryObjArr.filter(
      (_, index: number) => index !== indexToRemove
    );
    setForm({
      ...form,
      nurseryObjArr: updatedNurseryNames,
    });
  };

  const handleNurseryNameChange = (val: string, index: number) => {
    const updatedNurseryNames = [...form.nurseryObjArr];
    updatedNurseryNames[index] = { name: val };
    setForm({
      ...form,
      nurseryObjArr: updatedNurseryNames,
    });
  };

  useEffect(() => {
    if (props.success && props.success?.message) {
      Swal.fire({
        title: "Successfully !!!",
        text: props.success.message,
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`${process.env.PUBLIC_URL}/dashboard/users/`, {
            replace: true,
          });
          // Clear the success message after navigating
          props.setMessage({ success: {}, error: {} });
        }
      });
    }

    if (props.error && props.error?.message) {
      Swal.fire({
        title: "Error !!!",
        text: props.error.message,
        icon: "error",
      });

      // Clear the error message after displaying it
      props.setMessage({ success: {}, error: {} });
    }
  }, [props.success, props.error, navigate]);

  return (
    <div className="page-body">
      {props.isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1",
          }}
        >
          <Spinner size="lg" color="primary">
            Loading...
          </Spinner>
        </div>
      )}
      <Container fluid className={`${props.isLoading ? "custom-opacity" : ""}`}>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add User Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/users/`);
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <span className="h4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Personal Detail
                      </span>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>
                            {FirstName} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.firstName ? "is-invalid" : ""
                            } ${
                              validate && !errors.firstName ? "is-valid" : ""
                            }`}
                            placeholder="Enter first name"
                            {...register("firstName", {
                              required: "Please enter valid first name",
                            })}
                            value={form.firstName}
                            onChange={(e) =>
                              saveDataForm("firstName", e.target.value)
                            }
                          />
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {LastName} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            } ${
                              validate && !errors.lastName ? "is-valid" : ""
                            }`}
                            {...register("lastName", {
                              required: "Please enter valid last name",
                            })}
                            placeholder="Enter last name"
                            value={form.lastName}
                            onChange={(e) =>
                              saveDataForm("lastName", e.target.value)
                            }
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Email"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              } ${validate && !errors.email ? "is-valid" : ""}`}
                              {...register("email", {
                                required: "Please enter valid email",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                  message: "Invalid email address",
                                },
                              })}
                              placeholder="Enter your email"
                              value={form.email}
                              onChange={(e) =>
                                saveDataForm("email", e.target.value)
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-envelope text-primary" />
                            </InputGroupText>
                            {errors.email && (
                              <FormFeedback tooltip>
                                {errors.email.message}
                              </FormFeedback>
                            )}
                            {validate && !errors.email && (
                              <FormFeedback tooltip valid>
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Mobile"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="mobile"
                              className={`form-control ${
                                errors.phoneNo ? "is-invalid" : ""
                              } ${
                                validate && !errors.phoneNo ? "is-valid" : ""
                              }`}
                              {...register("phoneNo", {
                                required: "Mobile number is required", // Mobile number is required
                                pattern: {
                                  value: /^[0-9]+$/, // Validate only digits
                                  message:
                                    "Mobile number must contain only digits",
                                },
                                minLength: {
                                  value: 10, // Minimum length (for example 10 digits)
                                  message:
                                    "Mobile number must be at least 10 digits long",
                                },
                                maxLength: {
                                  value: 10, // Maximum length (for example 10 digits)
                                  message:
                                    "Mobile number must not exceed 10 digits",
                                },
                              })}
                              placeholder="Enter your mobile"
                              value={form.phoneNo}
                              onChange={(e) =>
                                saveDataForm("phoneNo", e.target.value)
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-phone text-primary" />
                            </InputGroupText>
                            {errors.phoneNo && (
                              <FormFeedback tooltip>
                                {errors.phoneNo.message}
                              </FormFeedback>
                            )}
                            {validate && !errors.phoneNo && (
                              <FormFeedback tooltip valid>
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Join date"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="joinDate"
                              rules={{
                                required: "Please select joining date.",
                              }}
                              render={({ field }) => {
                                console.log("field: ", field);
                                return (
                                  <DatePicker
                                    className={`form-control flatpickr-input ${
                                      errors.joinDate ? "is-invalid" : ""
                                    } ${
                                      validate && !errors.joinDate
                                        ? "is-valid"
                                        : ""
                                    }`}
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("joinDate", date);
                                    }}
                                    placeholderText="Select a date"
                                    dateFormat="MMMM dd,yyyy"
                                  />
                                );
                              }}
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-calendar text-primary" />
                            </InputGroupText>
                            {errors.joinDate && (
                              <FormFeedback
                                tooltip
                                style={{ display: "block" }}
                              >
                                <>{errors?.joinDate?.message}</>
                              </FormFeedback>
                            )}
                            {validate && !errors.joinDate && (
                              <FormFeedback
                                tooltip
                                valid
                                style={{ display: "block" }}
                              >
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Subscription Type"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="packageId"
                            control={control}
                            rules={{
                              required: "Please select any one package",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="subscription_type"
                                className={`${
                                  errors.packageId ? "is-invalid" : ""
                                } ${
                                  validate && !errors.packageId
                                    ? "is-valid"
                                    : ""
                                }`}
                                options={plansData}
                                value={plansData.find(
                                  (option: any) => option.value === field.value
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "packageId",
                                    selectedOption.value
                                  );
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                          {errors.packageId && (
                            <FormFeedback tooltip style={{ display: "block" }}>
                              <>{errors?.packageId?.message}</>
                            </FormFeedback>
                          )}
                          {validate && !errors.packageId && (
                            <FormFeedback
                              tooltip
                              valid
                              style={{ display: "block" }}
                            >
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-3">
                      <span className="h4 mt-3 d-block mb-4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Nursery Detail
                      </span>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleAddField}
                      >
                        Add Nursery
                      </button>
                      {form.nurseryObjArr.map((nurseryName, index) => {
                        return (
                          <>
                            <Row className="mt-3">
                              <Col md={3}>
                                <Label>
                                  {`Nursery Name`}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <div className="d-flex align-items-center position-relative">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.nurseryObjArr ? "is-invalid" : ""
                                    } ${
                                      validate && !errors.nurseryObjArr
                                        ? "is-valid"
                                        : ""
                                    }`}
                                    {...register("nurseryObjArr", {
                                      required:
                                        "Please select valid Nursery Name",
                                    })}
                                    placeholder="Enter nursery name"
                                    value={nurseryName.name}
                                    onChange={(e: any) =>
                                      handleNurseryNameChange(
                                        e.target.value,
                                        index
                                      )
                                    }
                                  />
                                  {errors.nurseryObjArr && (
                                    <FormFeedback tooltip>
                                      {errors.nurseryObjArr.message}
                                    </FormFeedback>
                                  )}
                                  {validate && !errors.nurseryObjArr && (
                                    <FormFeedback tooltip valid>
                                      {LooksGood}
                                    </FormFeedback>
                                  )}
                                  <span
                                    className="p-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleRemoveField(index)}
                                  >
                                    <i className="iconly-Close-Square icli h2"></i>
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                    </Col>
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Save & List`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        className="mt-3 btn-md"
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/users/`
                          );
                        }}
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connectComponent(AddUsers);
