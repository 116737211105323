import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../AbstractElements";
import { billingOptions, LooksGood } from "../../../Utils/Constants";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { connectComponent } from "./connector";
import { IForm } from "./Helper/types";
import Select from "react-select";
import Swal from "sweetalert2";

const AddPlan = (props: any) => {
  const navigate = useNavigate();
  const initialState: IForm = {
    title: "",
    subTitle: "",
    billing: "YEARLY",
    fee: "",
    maxNumOfChild: "",
    description: "",
  };

  const [form, setForm] = useState<IForm>({
    ...initialState,
  });

  const [validate, setValidate] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>();
  const onSubmit = () => {
    setValidate(true);
    props.addForm(form);
  };

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (props.success && props.success?.message) {
      Swal.fire({
        title: "Successfully !!!",
        text: props.success.message,
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`${process.env.PUBLIC_URL}/dashboard/plans/`, {
            replace: true,
          });
          // Clear the success message after navigating
          props.setMessage({ success: {}, error: {} });
        }
      });
    }

    if (props.error && props.error?.message) {
      Swal.fire({
        title: "Error !!!",
        text: props.error.message,
        icon: "error",
      });

      // Clear the error message after displaying it
      props.setMessage({ success: {}, error: {} });
    }
  }, [props.success, props.error, navigate]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add Plan Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/plans/`);
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>
                            {`Title`} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.title ? "is-invalid" : ""
                            } ${validate && !errors.title ? "is-valid" : ""}`}
                            placeholder="Enter title"
                            {...register("title", {
                              required: "Please enter valid title",
                            })}
                            value={form.title}
                            onChange={(e) => {
                              saveDataForm("title", e.target.value);
                            }}
                          />
                          {errors.title && (
                            <FormFeedback tooltip>
                              {errors.title.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.title && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Billing"} <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="billing"
                            control={control}
                            rules={{
                              required: "Please select any one billing",
                            }} // Validation rules
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="subscription_type"
                                className={`${
                                  errors.billing ? "is-invalid" : ""
                                } ${
                                  validate && !errors.billing ? "is-valid" : ""
                                }`}
                                options={billingOptions}
                                value={billingOptions.find(
                                  (option: any) => option.value === field.value
                                )} // Set current value
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm("billing", selectedOption.value);
                                }} // Update the value using Controller's onChange
                              />
                            )}
                          />
                          {errors.billing && (
                            <FormFeedback tooltip style={{ display: "block" }}>
                              <>{errors?.billing?.message}</>
                            </FormFeedback>
                          )}
                          {validate && !errors.billing && (
                            <FormFeedback
                              tooltip
                              valid
                              style={{ display: "block" }}
                            >
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4} className="position-relative">
                          <Label>
                            {"Fee"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <InputGroupText className="list-light-primary">
                              <i className="fas fa-pound-sign"></i>
                            </InputGroupText>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.fee ? "is-invalid" : ""
                              } ${validate && !errors.fee ? "is-valid" : ""}`}
                              placeholder="Enter your fee"
                              {...register("fee", {
                                required: "Please enter your valid fee",
                                pattern: {
                                  value: /^[0-9]+$/, // Validate only digits
                                  message:
                                    "Fee number must contain only digits",
                                },
                              })}
                              value={form.fee}
                              min={0}
                              onChange={(e) => {
                                if (Number(e.target.value) > 0) {
                                  saveDataForm("fee", e.target.value);
                                }
                              }}
                            />
                            {errors.fee && (
                              <FormFeedback tooltip>
                                {errors.fee.message}
                              </FormFeedback>
                            )}
                            {validate && !errors.fee && (
                              <FormFeedback tooltip valid>
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Number of children"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="number"
                              className={`form-control ${
                                errors.maxNumOfChild ? "is-invalid" : ""
                              } ${
                                validate && !errors.maxNumOfChild
                                  ? "is-valid"
                                  : ""
                              }`}
                              placeholder="Enter your number of children"
                              {...register("maxNumOfChild", {
                                required:
                                  "Please enter your valid number of children",
                                pattern: {
                                  value: /^[0-9]+$/, // Validate only digits
                                  message:
                                    "Fee number must contain only digits",
                                },
                              })}
                              min={0}
                              value={form.maxNumOfChild}
                              onChange={(e) =>
                                saveDataForm("maxNumOfChild", e.target.value)
                              }
                            />
                            {errors.maxNumOfChild && (
                              <FormFeedback tooltip>
                                {errors.maxNumOfChild.message}
                              </FormFeedback>
                            )}
                            {validate && !errors.maxNumOfChild && (
                              <FormFeedback tooltip valid>
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mt-3 position-relative">
                      <Label>
                        {`Description`} <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Description is required" }} // Validation for the description field
                        render={({ field }) => {
                          console.log("field: ", field);
                          return (
                            <ReactQuill
                              theme="snow"
                              value={field.value}
                              onChange={(value) => {
                                field.onChange(
                                  value === "<p><br></p>" ? "" : value
                                );
                                saveDataForm(
                                  "description",
                                  value === "<p><br></p>" ? "" : value
                                );
                              }} // Update form value on change
                              onBlur={field.onBlur} // Optional: handle onBlur event
                            />
                          );
                        }}
                      />
                      {errors.description && (
                        <FormFeedback
                          tooltip
                          style={{
                            display: "block",
                            top: "-7px",
                            left: "120px",
                            right: "unset",
                          }}
                        >
                          {errors.description.message}
                        </FormFeedback>
                      )}
                      {validate && !errors.description && (
                        <FormFeedback
                          tooltip
                          valid
                          style={{ display: "block" }}
                        >
                          {LooksGood}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Save & List`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/plans/`
                          );
                        }}
                        className="mt-3 btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default connectComponent(AddPlan);
