export const stepperHorizontalData = [
    { id: 1, divClass: 'aboutblock', icon: 'user', title: "About", detail: "Add personal details" },
    { id: 2, divClass: 'addressblock', icon: 'location-arrow', title: "Address", detail: "Add additional info" },
    { id: 3, divClass: 'verifyblock', icon: 'id-card', title: "Verify", detail: "Complete.. !" },
];

export const socialLinksData = [
    {
        id: 1,
        link: "https://www.linkedin.com",
        icon: 'linkedin',
    },
    {
        id: 2,
        link: "https://twitter.com",
        icon: 'twitter',
    },
    {
        id: 3,
        link: "https://www.facebook.com",
        icon: 'facebook',
    },
    {
        id: 4,
        link: "https://www.instagram.com",
        icon: 'instagram',
    }
]

export const verifyFormCountry = ['Canada', 'United States', 'United Kingdom', 'Germany', 'France']