import { IForm } from "../../../Pages/SuperAdmin/HelpCenters/HelpCenter/Helper/types";
import { HelpCenterApiSlice } from "./HelpCenterApiSlice";

export const getHelpCenterData = async (filterObj: any, dispatch: any) => {
  return dispatch(
    HelpCenterApiSlice.endpoints.getHelpCenter.initiate(filterObj, {
      forceRefetch: true,
    })
  );
};

export const addHelpCenterData = async (formData: IForm, thunkApi: any) => {
  return thunkApi.dispatch(
    HelpCenterApiSlice.endpoints.createHelpCenter.initiate(formData)
  );
};

export const updateHelpCenterData = async (formData: any, thunkApi: any) => {
  return thunkApi.dispatch(
    HelpCenterApiSlice.endpoints.updateHelpCenter.initiate(formData)
  );
};
