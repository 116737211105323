import { IForm } from "../../../Pages/SuperAdmin/HelpCenters/HelpCenter/Helper/types";
import { FaqApiSlice } from "./FaqApiSlice";

export const getFaqData = async (filterObj: any, dispatch: any) => {
  return dispatch(
    FaqApiSlice.endpoints.getFaq.initiate(filterObj, {
      forceRefetch: true,
    })
  );
};

export const addFaqData = async (formData: IForm, thunkApi: any) => {
  return thunkApi.dispatch(
    FaqApiSlice.endpoints.createFaq.initiate(formData)
  );
};

export const updateFaqData = async (formData: any, thunkApi: any) => {
  return thunkApi.dispatch(
    FaqApiSlice.endpoints.updateFaq.initiate(formData)
  );
};
