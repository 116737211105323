import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IForm } from "../../../Pages/SuperAdmin/HelpCenters/HelpCenter/Helper/types";
import { addHelpCenterData, updateHelpCenterData } from "./meditator";
import { handleErrors } from "../../../Utils";

interface IHelpCenter {
  id: string | number;
  createdAt?: string;
  updatedAt?: string;
  title: string;
  subTitle: string;
  content?: string;
}

interface IInitialState {
  helpCenterData: IHelpCenter[];
  isLoading: boolean;
  error: any;
  success: any;
}

const initialState: IInitialState = {
  helpCenterData: [],
  isLoading: false,
  error: {},
  success: {},
};

const HelpCenterSlice = createSlice({
  name: "HelpCenter",
  initialState,
  reducers: {
    SET_HELPCENTER: (state: IInitialState, action) => {    
      state.helpCenterData = action.payload.helpcenters;    
    },
    FETCH_HELPCENTER: () => {
      // * To get the user data
    },
    SET_MESSAGE: (state, action) => {
      state.error = action.payload.error;
      state.success = action.payload.success;
    },
  },
  extraReducers: (builder) => {},
});

export const addForm = createAsyncThunk(
  "addForm",
  async (formData: IForm, thunkApi) => {
    const response = await addHelpCenterData(formData, thunkApi);
    console.log(response);
    if (response?.data && response?.data?.success) {
      thunkApi.dispatch(
        SET_MESSAGE({ success: { status: true, message: "Help Center Created Successfully!" }, error: {} })
      );
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message: "Facing issue while adding help center.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const updateForm = createAsyncThunk(
  "updateForm",
  async (formData: IForm, thunkApi) => {
    const response = await updateHelpCenterData(formData, thunkApi);
    console.log(response);
    if (response?.data && response?.data?.success) {
      thunkApi.dispatch(
        SET_MESSAGE({ success: { status: true, message: "Help Center Updated Successfully!" }, error: {} })
      );
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message: "Facing issue while updating help center.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const { SET_HELPCENTER, FETCH_HELPCENTER, SET_MESSAGE } =
  HelpCenterSlice.actions;
export default HelpCenterSlice.reducer;
