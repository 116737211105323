import { createListenerMiddleware } from "@reduxjs/toolkit";
import { FETCH_HELPCENTER, SET_HELPCENTER } from "./HelpCenterReducer";
import { getHelpCenterData } from "./meditator";

export const HelpCenterListener = createListenerMiddleware();

HelpCenterListener.startListening({
  actionCreator: FETCH_HELPCENTER,
  effect: async (action, listenerAPI) => {
    let state: any = listenerAPI.getState();
    const { dispatch } = listenerAPI;
    const resp = await getHelpCenterData(action.payload, dispatch);
    console.log(resp);
    if (resp.isSuccess) {
      const helpCenterData = resp.data.data;
      if (helpCenterData.length > 0) {
        dispatch(
          SET_HELPCENTER({
            helpcenters: helpCenterData,
          })
        );
      }
    }
  },
});
