import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { PagesTitle, SamplePageTitle } from "../../../Utils/Constants";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../Types/Tables.type";
import {
  htmlColumns,
  htmlColumnsTableData,
} from "../../../Data/Tables/DataTables/DataSource";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";

const MyCalendar = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState<Date>(new Date());
  const tileContent = ({ date, view }: any) => {
    // Example: Add event markers
    const events = [15, 22]; // Dates with events
    return view === "month" && events.includes(date.getDate()) ? (
      <span className="event-marker">🎉</span>
    ) : null;
  };
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div className="d-flex justify-content-between align-items-center">
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`My Calendar`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
              </div>
              <CardBody>
                <Calendar
                  onChange={(value) => setDate(value as Date)}
                  value={date}
                  tileContent={tileContent}
                  className="custom-calendar w-100"
                  nextLabel="Next Month"
                  prevLabel="Previous Month"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyCalendar;
