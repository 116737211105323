import { connect } from "react-redux";
import {
  addForm,
  FETCH_PLANS,
  SET_MESSAGE,
  updateForm,
} from "../../../ReduxToolkit/Slices/Package/PackageReducer";

const mapStateToProps = (state: any) => {
  return {
    plans: state.plans.plans,
    purchasedPlans: state.plans.purchasedPlans,
    isLoading: state.plans.isLoading,
    error: state.plans.error,
    success: state.plans.success,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchPlans: (value: any) => dispatch(FETCH_PLANS(value)),
    addForm: (value: any) => dispatch(addForm(value)),
    updateForm: (value: any) => dispatch(updateForm(value)),
    setMessage: (value: any) => {
      dispatch(SET_MESSAGE(value));
    },
  };
};

export const connectComponent = (Component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
};
