import React from "react";
import { H3, LI } from "../../../../AbstractElements";
import { Href } from "../../../../Utils/Constants";

export default function CloudDesign() {
  return (
    <LI className="cloud-design">
      <a className="cloud-mode" href={Href}>
        <svg
          className="climacon climacon_cloudDrizzle"
          id="cloudDrizzle"
          version="1.1"
          viewBox="15 15 70 70"
        >
          <g className="climacon_iconWrap climacon_iconWrap-cloudDrizzle">
            <g className="climacon_wrapperComponent climacon_wrapperComponent-drizzle">
              <path
                className="climacon_component climacon_component-stroke climacon_component-stroke_drizzle climacon_component-stroke_drizzle-left"
                d="M42.001,53.644c1.104,0,2,0.896,2,2v3.998c0,1.105-0.896,2-2,2c-1.105,0-2.001-0.895-2.001-2v-3.998C40,54.538,40.896,53.644,42.001,53.644z"
              ></path>
              <path
                className="climacon_component climacon_component-stroke climacon_component-stroke_drizzle climacon_component-stroke_drizzle-middle"
                d="M49.999,53.644c1.104,0,2,0.896,2,2v4c0,1.104-0.896,2-2,2s-1.998-0.896-1.998-2v-4C48.001,54.54,48.896,53.644,49.999,53.644z"
              ></path>
              <path
                className="climacon_component climacon_component-stroke climacon_component-stroke_drizzle climacon_component-stroke_drizzle-right"
                d="M57.999,53.644c1.104,0,2,0.896,2,2v3.998c0,1.105-0.896,2-2,2c-1.105,0-2-0.895-2-2v-3.998C55.999,54.538,56.894,53.644,57.999,53.644z"
              ></path>
            </g>
            <g className="climacon_wrapperComponent climacon_wrapperComponent-cloud">
              <path
                className="climacon_component climacon_component-stroke climacon_component-stroke_cloud"
                d="M63.999,64.944v-4.381c2.387-1.386,3.998-3.961,3.998-6.92c0-4.418-3.58-8-7.998-8c-1.603,0-3.084,0.481-4.334,1.291c-1.232-5.316-5.973-9.29-11.664-9.29c-6.628,0-11.999,5.372-11.999,12c0,3.549,1.55,6.729,3.998,8.926v4.914c-4.776-2.769-7.998-7.922-7.998-13.84c0-8.836,7.162-15.999,15.999-15.999c6.004,0,11.229,3.312,13.965,8.203c0.664-0.113,1.336-0.205,2.033-0.205c6.627,0,11.998,5.373,11.998,12C71.997,58.864,68.655,63.296,63.999,64.944z"
              ></path>
            </g>
          </g>
        </svg>
        <H3>{"15"}</H3>
      </a>
    </LI>
  );
}
