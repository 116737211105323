import { connect } from "react-redux";
import { addForm, FETCH_FAQ, SET_MESSAGE, updateForm } from "../../../ReduxToolkit/Slices/Faq/FaqReducer";


const mapStateToProps = (state: any) => {
  console.log(state);
  return {
    faqs: state.faqs.faqs,
    isLoading: state.faqs.isLoading,
    error: state.faqs.error,
    success: state.faqs.success,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadFaqData: (value: any) => dispatch(FETCH_FAQ(value)),
    addForm: (value: any) => dispatch(addForm(value)),
    updateForm: (value: any) => dispatch(updateForm(value)),
    setMessage: (value: any) => {
      console.log(value);
      dispatch(SET_MESSAGE(value))
    },
  };
};

export const connectComponent = (Component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

// export default connect(mapStateToProps, mapDispatchToProps)(Users);
