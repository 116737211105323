import { connect } from "react-redux";
import { addForm, FETCH_HELPCENTER, SET_MESSAGE, updateForm } from "../../../../ReduxToolkit/Slices/HelpCenter/HelpCenterReducer";

const mapStateToProps = (state: any) => {
  console.log(state);
  return {
    helpcenters: state.helpCenter.helpCenterData,
    isLoading: state.helpCenter.isLoading,
    error: state.helpCenter.error,
    success: state.helpCenter.success,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadHelpCenterData: (value: any) => dispatch(FETCH_HELPCENTER(value)),
    addForm: (value: any) => dispatch(addForm(value)),
    updateForm: (value: any) => dispatch(updateForm(value)),
    setMessage: (value: any) => {
      console.log(value);
      dispatch(SET_MESSAGE(value))
    },
  };
};

export const connectComponent = (Component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

// export default connect(mapStateToProps, mapDispatchToProps)(Users);
