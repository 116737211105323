import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { PagesTitle, SamplePageTitle } from "../../../Utils/Constants";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../Types/Tables.type";
import {
  htmlColumns,
  htmlColumnsTableData,
} from "../../../Data/Tables/DataTables/DataSource";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import { connectComponent } from "./connector";
import { flattenObject } from "../../../Utils";

const Faqs = (props: any) => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [saveData, setSaveData] = useState([]);

  const filteredItems: any = saveData.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const [filterObj, setFilterObj] = useState({ search: "" });

  useEffect(() => {
    props.loadFaqData(filterObj);
  }, []);

  useEffect(() => {
    const data = props.faqs.map((row: any) => {
      return flattenObject(row);
    });

    setSaveData(data);
  }, [props.faqs]);

  const handleDetailPage = (id: number | string) => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/help-center/faq/edit/${id}`);
  };
  
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`FAQ's`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    console.log("BUTTON CLICKED");
                    navigate(`${process.env.PUBLIC_URL}/dashboard/help-center/faq/add`);
                  }}
                >
                  <i className="fa fa-plus mr-2"></i> Add Faq
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                   onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns({
                      handleDetailPage: (id: number | string) =>
                        handleDetailPage(id),
                    })}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default connectComponent(Faqs);

