import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, prepareHeaders } from "../../../Utils/Constants/config";

export const HelpCenterApiSlice = createApi({
  reducerPath: "HelpCenterApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    createHelpCenter: builder.mutation({
      query: (body) => ({
          url: `/helpCenter/`,
          method: "POST",
          body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateHelpCenter: builder.mutation({
      query: ({ id, body }) => ({
        url: `/helpCenter/${id}`,
        method: "PATCH",
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getHelpCenter: builder.query({
      query: (params) => {
        return {
          url: "/helpCenter/all",
          method: "GET",
          params: params,
        };
      },
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    deleteHelpCenter: builder.mutation({
      query: (id) => ({
        url: `/helpCenter/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
  }),
});
