import { TableColumn } from "react-data-table-component";
import { Badges, LI, UL } from "../../../../../AbstractElements";
import { CustomCellInterFace } from "./types";
import { useState } from "react";

// export const htmlColumns: TableColumn<HtmlColumnsInterface>[] = [
const CustomBadge = ({ position, color }: CustomCellInterFace) => {
  const [model, setModel] = useState(false);
  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(255, 237, 236)",
          width: "100px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <span
          style={{
            padding: "4px 0px",
            color: "rgb(224, 49, 55)",
            textTransform: "uppercase",
            fontWeight: "700",
            fontSize: "10px",
          }}
        >
          {position}
        </span>
      </div>
      <span
        style={{ padding: "4px 4px", fontWeight: "700", fontSize: "16px" }}
        onClick={() => setModel(!model)}
      >
        <i className="iconly-Arrow-Down-2 icli"></i>
      </span>
      {model && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            zIndex: "1",
            width: "150px",
            height: "90px",
            background: "#000",
          }}
        >
          <h2>Hello</h2>
        </div>
      )}
    </>
  );
};

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

export const columns = ({
  handleDetailPage,
}: {
  handleDetailPage: (id: number | string) => void;
}) => {
  const columnList: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Sub Title" />,
      selector: (row) => row.subTitle,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => (row?.isActive === true ? "ACTIVE" : "INACTIVE"),
      style: { maxWidth: "50%" },
      cell: (row) => (
        <CustomBadge
          color={row.color}
          position={row?.isActive === true ? "ACTIVE" : "INACTIVE"}
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      center: true,
      style: { maxWidth: "50%" },
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href={"javascript:void(0);"}
              onClick={() => handleDetailPage(row.id)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
          {/* <LI className="delete">
            <a
              href={""}
              className="btn btn-danger d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-trash text-white" />
            </a>
          </LI> */}
        </UL>
      ),
    },
  ];

  return columnList;
};