import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import { connectComponent } from "./connector";
import { IFilter } from "./Helper/types";
import { IPlanObj } from "../../../ReduxToolkit/Slices/Package/PackageReducer";
import { flattenObject } from "../../../Utils";
import Swal from "sweetalert2";

const Plan = (props: any) => {
  const navigate = useNavigate();
  const [saveData, setSaveData] = useState<IPlanObj[]>([]);
  const [filterText, setFilterText] = useState("");
  const [filterObj, setFilterObj] = useState<IFilter>({
    page: 1,
    pageSize: 10,
    status: "",
    billing: "",
  });

  const filteredItems: any = saveData.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  useEffect(() => {
    const data = props.plans.map((row: any) => {
      return flattenObject(row);
    });
    setSaveData(data);
  }, [props.plans]);

  useEffect(() => {
    props.fetchPlans(filterObj);
  }, []);

  const handleDetailPage = (id: number | string) => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/plans/edit/${id}`);
  };

  const handleStatusChange = (row: any) => {
    const selectedValue = row?.isActive === true ? "active" : "inactive";
    const id = row.id;
    Swal.fire({
      title: "Update Status",
      input: "select",
      inputOptions: {
        active: "ACTIVE",
        inactive: "IN ACTIVE",
      },
      inputPlaceholder: "Select a status",
      inputValue: selectedValue,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.updateForm({
          id: id,
          body: { isActive: result.value === "active" ? true : false },
          filterObj,
        });
      }
    });
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Plan Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/plans/add`);
                  }}
                >
                  Add New Plan
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />
                <div className="table-responsive">
                  {useMemo(() => {
                    return (
                      <>
                        <DataTable
                          data={filteredItems}
                          columns={columns({
                            handleDetailPage: (id: number | string) =>
                              handleDetailPage(id),
                            handleStatusChange: (row: any) =>
                              handleStatusChange(row),
                          })}
                          pagination
                          className="display"
                          conditionalRowStyles={[
                            {
                              when: (row) => true, // This applies to all rows
                              style: {
                                "&:hover": {
                                  backgroundColor: "rgba(48, 142, 135, 0.2)",
                                  cursor: "pointer",
                                },
                              },
                            },
                          ]}
                        />
                      </>
                    );
                  }, [props.isLoading, props.success, saveData])}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connectComponent(Plan);
