import { createListenerMiddleware } from "@reduxjs/toolkit";
import { FETCH_FAQ, SET_FAQ} from "./FaqReducer";
import { getFaqData } from "./meditator";

export const FaqListener = createListenerMiddleware();

FaqListener.startListening({
  actionCreator: FETCH_FAQ,
  effect: async (action, listenerAPI) => {
    let state: any = listenerAPI.getState();
    // console.log(state.faqData);
    const { dispatch } = listenerAPI;
    const resp = await getFaqData(action.payload, dispatch);
    console.log(state);
    if (resp.isSuccess) {
      const faqData = resp.data.data;
      console.log("hii", faqData);
      if (faqData.length > 0) {
        dispatch(
          SET_FAQ({
            faqs: faqData,
          })
        );
      }
    }
  },
});
