import { useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { manageRoutes, skipAuthenticationPublicPath } from "./Helper";

const CustomRoutes = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search, hash } = location;
  useEffect(() => {
    props.getUserInfo();

    // if (
    //   !skipAuthenticationPublicPath.includes(pathname) &&
    //   props.auth.user === null
    // ) {
    //   navigate("/login");
    // }
  }, []);

  return <MyRoutes auth={props.auth} />;
};

const MyRoutes = (props: any) => {
  return useRoutes([...manageRoutes(props.auth)]);
};

export default CustomRoutes;
