import { forEach, isArray, isObject } from "lodash";
interface AnyObject {
  [key: string]: any;
}

//dynamic image
const images = require.context(`/public/assets/images`, true);

export const dynamicImage = (image: string | undefined) => {
  return images(`./${image}`);
};

//dynamic Number
export function dynamicNumber(totalLength: number) {
  return Array.from({ length: totalLength }, (_, index) => index + 1);
}

export const getFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  return `${firstName || ""} ${lastName || ""}`;
};

export const flattenObject = (
  obj: any,
  parent = "",
  res: any = {},
  visited: any = new Set()
) => {
  if (visited.has(obj)) {
    // Avoid circular reference
    res[parent] = "[Circular]";
    return res;
  }

  visited.add(obj); // Add current object to visited

  forEach(obj, (value, key) => {
    const newKey = parent ? `${parent}.${key}` : key;

    if (isObject(value) && !isArray(value) && value !== null) {
      flattenObject(value, newKey, res, visited);
    } else if (isArray(value)) {
      forEach(value, (item, index) => {
        flattenObject(item, `${newKey}[${index}]`, res, visited);
      });
    } else {
      res[newKey] = value;
    }
  });

  visited.delete(obj); // Remove object from visited after processing
  return res;
};

export const handleErrors = (errors: any) => {
  return Object.fromEntries(Object.keys(errors).map((key) => [key, ""]));
};

export function removeKeys(obj: AnyObject, keysToRemove: string[]): AnyObject {
  // Create a new object to store the result
  const result: AnyObject = { ...obj };

  // Remove specified keys from the result object
  keysToRemove.forEach((key) => delete result[key]);

  return result;
}

export const delay = (seconds: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000); // Convert seconds to milliseconds
  });
};

export const getDataFromArray = (array: any, field: string, id: any) => {
  const filteredData = array.filter((row: any) => row.id === id);
  if (filteredData.length > 0) {
    return filteredData[0][field];
  }
  return "";
};

// Function to convert data to CSV format
export const convertToCSV = (data: any) => {
  const csvRows = [];

  // Get headers (keys of the object)
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(",")); // Push headers row

  // Loop through data and push each row
  for (const row of data) {
    const values = headers.map((header) => row[header]);
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
};
