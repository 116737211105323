import React, { FormEvent, useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Btn, H2, H3, H6, Image, P } from "../AbstractElements";
import { dynamicImage } from "../Utils";
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
  SignInAccount,
} from "../Utils/Constants";
import SocialLink from "./SocialLink";
import Loader from "../Layout/AdminLayout/Loader";
import CommonLogo from "../Components/Pages/Others/Authentication/Common/CommonLogo";
import SocialLinks from "../Components/Pages/Others/Authentication/Common/SocialLinks";

export default function NewSignInForm({ logoClass, ...props }: any) {
  const navigate = useNavigate();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toggle = () => setPasswordVisible(!isPasswordVisible);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    return props.authenticateUser({ email, password });
  };

  useEffect(() => {
    if (props.success) {
      toast.success(props.success);
      props.setSuccess("");
      navigate(`${process.env.PUBLIC_URL}/dashboard`);
    }
    if (props.error) {
      toast.error(props.error);
      props.setError("");
    }
  }, [props.error, props.success]);

  if (props.isLoadingUser) {
    return <Loader style={{ width: "100%" }} />;
  }

  return (
    <>
      <Container fluid>
          <Row>
              <Col xl={5} className=''>
              <div
                style={{
                  backgroundImage: `url(${dynamicImage(
                    `login/logo_new_login.jpg`
                  )})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "100vh",
                }}
              ></div>
              </Col>
              <Col xl={7} className="p-0">
                  <div className="login-card login-dark login-bg">
                  <div>
                      <div>
                          <CommonLogo logoClass={logoClass} />
                      </div>
                        <div className="login-main">
                            <Form className="theme-form" onSubmit={handleSubmit}>
                                <H2 className="text-center">{SignInAccount}</H2>
                                <P className="text-center">{"Enter your email & password to login"}</P>
                                <FormGroup>
                                    <Col><Label>{EmailAddress}</Label></Col>
                                    <Input 
                                      type="email"
                                      required
                                      placeholder="Email"
                                      defaultValue={email}
                                      onChange={(event) => setEmail(event.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Col>
                                      <Label>{Password}</Label>
                                    </Col>
                                    <div className="form-input position-relative">
                                        <Input 
                                          type={isPasswordVisible ? 'text' : 'password'} 
                                          defaultValue={password}
                                          required={true}
                                          placeholder="*********"
                                          onChange={(event) => setPassword(event.target.value)}
                                          name="login[password]"
                                        />
                                        <div className='show-hide' onClick={toggle}>
                                            <span className={!isPasswordVisible ? 'show' : ''}></span>
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup className="mb-0 checkbox-checked">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <FormGroup className="checkbox-solid-info" check>
                                      <Input id="checkbox1" type="checkbox" />
                                      <Label htmlFor="checkbox1" check>
                                        {RememberPassword}
                                      </Label>
                                    </FormGroup>
                                    <Link
                                      className="link"
                                      to={`${process.env.PUBLIC_URL}/authentication/forget_password`}
                                    >
                                      {ForgotPassword}
                                    </Link>
                                  </div>

                                  <div className="text-end mt-3">
                                    <Btn color="primary" className="w-100" block>
                                      {SignIn}
                                    </Btn>
                                  </div>
                                </FormGroup>
                                <SocialLinks />
                            </Form>
                        </div>
                      </div>
                  </div>
              </Col>
          </Row>
      </Container>
    </>
  );
}
