import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IForm } from "../../../Pages/SuperAdmin/HelpCenters/HelpCenter/Helper/types";
import { addFaqData, updateFaqData } from "./meditator";
import { handleErrors } from "../../../Utils";

interface IFaqs {
  id: string | number;
  title: string;
  content: string;
  createdAt: string;
  updatedAt: number;
}

interface IInitialState {
  faqs: IFaqs[];
  isLoading: boolean;
  error: any;
  success: any;
}

const initialState: IInitialState = {
  faqs: [],
  isLoading: false,
  error: {},
  success: {},
};

const FaqSlice = createSlice({
  name: "Faq",
  initialState,
  reducers: {
    SET_FAQ: (state: IInitialState, action) => {    
      state.faqs = action.payload.faqs;    

    },
    FETCH_FAQ: () => {
      // * To get the user data
    },
    SET_MESSAGE: (state, action) => {
      state.error = action.payload.error;
      state.success = action.payload.success;
    },
  },
  extraReducers: (builder) => {},
});

export const addForm = createAsyncThunk(
  "addForm",
  async (formData: IForm, thunkApi) => {
    const response = await addFaqData(formData, thunkApi);
    console.log(response);
    if (response?.data && response?.data?.success) {
      thunkApi.dispatch(
        SET_MESSAGE({ success: { status: true, message: "FAQ Created Successfully!" }, error: {} })
      );
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message: "Facing issue while adding faq.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const updateForm = createAsyncThunk(
  "updateForm",
  async (formData: IForm, thunkApi) => {
    const response = await updateFaqData(formData, thunkApi);
    console.log(response);
    if (response?.data && response?.data?.success) {
      thunkApi.dispatch(
        SET_MESSAGE({ success: { status: true, message: "FAQ Updated Successfully!" }, error: {} })
      );
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message: "Facing issue while updating faq.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const { SET_FAQ, FETCH_FAQ, SET_MESSAGE } =
  FaqSlice.actions;
export default FaqSlice.reducer;
